import React from 'react'

import Hero from '../../components/Hero'
import ContactInfo from '../Contact/ContactInfo'
import Featured from '../../components/Featured'    
import Col from 'react-bootstrap/Col'

import { homeHero, featuredProjects, collageHome } from './Data'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Home.css'

function Home() {
    return (
        <div>
            <Hero {...homeHero}/>
            <div className="section-heading center featured-section-heading">
                FEATURED PROJECTS
            </div>
            <Featured/>
            <Col className="contact-details">
                <ContactInfo lightBackground={false} isColumn={true} hideButton={true} hideLocation={false} hidePhone={false} hideEmail={false}/>
            </Col>
        </div>
    )
}

export default Home
