import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Footer.css'

function Footer() {
    return (
        <>
            <div className="footer-rights">
                Ymaz Builders | All Rights Reserved 2021
            </div>
        </>
    )
}

export default Footer
