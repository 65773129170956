export const homeHero = {
    img: "/images/hero-home.jpg",
    fullHeight: true,
    centerAlign: true,
    headingLabel: "At Ymaz Builders, we want our clients to",
    headingText: "",
    headingTextBold: "LIVE THE GOOD LIFE\nTHEY DESERVE",
    headingColor: "#FFFFFF",
    hideDesc: true,
    hideCta: false,
    ctaLabel: "CALL US NOW",
    ctaLink: "/contact"
}

export const collageHome = [
    "images/collage/collage-home-1.jpg",
    "images/collage/collage-home-2.jpg",
    "images/collage/collage-home-3.jpg"
]

export const featuredProjects = [
    {
        image: "/images/listings/listing-3towers-0.jpg",
        label: "3 Towers Condominium (Parañaque)",
        category: "Condominium"
    },
    {
        image: "/images/listings/listing-boston-0.jpg",
        label: "Boston Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-brownswiss-0.jpg",
        label: "Brownswiss Project",
        category: "Warehouse/Industrial"
    },
    {
        image: "/images/listings/listing-ptuazon-0.jpg",
        label: "P. Tuazon Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-domingo-0.jpg",
        label: "Domingo St.",
        category: "Residential"
    },
    {
        image: "/images/listings/listing-tomasmorato-0.jpg",
        label: "Tomas Morato",
        category: "Commercial"
    },
    {
        image: "/images/listings/listing-mindanaoavenue-0.jpg",
        label: "Mindanao Avenue",
        category: "Warehouse/Industrial"
    },
    {
        image: "/images/listings/listing-donmiguel-0.jpg",
        label: "Don Miguel",
        category: "Residential"
    },
]