export const contactHero = {
    img: "/images/hero-contact-short.jpg",
    fullHeight: false,
    centerAlign: false,
    headingTextBold: "Get in Touch",
    headingColor: "#FFF",
    hideDesc: true,
    hideCta: true
}

export const contactInfo = {
    address: "#129 Congressional Ave.,\nBrgy. Bahay Toro, Quezon City",
    phoneNumber: "+(63)9152129564",
    email: "ymazbuilders2020@gmail.com",
    facebook: "https://www.facebook.com/ymazbuildersph",
    instagram: "",
    youtoube: "",
    twitter: "",
    tiktok: ""
}