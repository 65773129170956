import React, {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ProjectCard from './ProjectCard'

import { Link } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Featured.css'

function Featured() {
    const [items, setItems] = useState([]);

    useEffect( () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('https://cms.ymaz.bitgoo.cloud/admindashboardcms/api/getprojects', requestOptions)
            .then(results => results.json())
            .then(data => {
                console.log(data.data.projects);

                var items = data.data.projects.filter((projects) => { return (projects.projecttype === "ymaz") && (projects.isfeatured === 1)});
                setItems(items);
            })
            .catch( error => { console.log(error)})
    }, [] );

    return (
        <div className="section-container feature-container">
            <Container>
                <Row>
                    {
                        items.map((project) => {
                            return (
                                <Col md={3} sm={6}>
                                    <Link to={"/projects/" + project.label}>
                                        <ProjectCard projectImage={project.photoUrl[0]} projectLabel={project.label} projectCategory={project.category} />
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default Featured
