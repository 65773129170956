export const projectHero = {
    img: "/images/hero-projects-short.jpg",
    fullHeight: false,
    centerAlign: true,
    headingTextBold: "OUR PROJECTS",
    headingColor: "#FFF",
    hideDesc: true,
    hideCta: true
}

export const singleProjectHero = {
    img: "/images/hero-projects-short.jpg",
    fullHeight: false,
    centerAlign: true,
    headingColor: "#FFF",
    hideDesc: true,
    hideCta: true
}

export const featuredProjects = [
    {
        image: "/images/listings/listing-boston-0.jpg",
        label: "Boston Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-may-0.jpg",
        label: "May St.",
        category: "Residential"
    },
    {
        image: "/images/listings/listing-mindanaoavenue-0.jpg",
        label: "Mindanao Avenue",
        category: "Warehouse/Industrial"
    },
    {
        image: "/images/listings/listing-brownswiss-0.jpg",
        label: "Brownswiss Project",
        category: "Warehouse/Industrial"
    }
]

export const filterListings = [ 
    "All",
    "Commercial", 
    "Condominium", 
    "Warehouse/Industrial", 
    "Townhouse",
    "Residential" ]

export const projectListings = [
    {
        image: "/images/listings/listing-3towers-0.jpg",
        label: "3 Towers Condominium (Parañaque)",
        category: "Condominium"
    },
    {
        image: "/images/listings/listing-alabama-0.jpg",
        label: "Alabama Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-boston-0.jpg",
        label: "Boston Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-brownswiss-0.jpg",
        label: "Brownswiss Project",
        category: "Warehouse/Industrial"
    },
    {
        image: "/images/listings/listing-cristobal-0.jpg",
        label: "Cristobal Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-domingo-0.jpg",
        label: "Domingo St.",
        category: "Residential"
    },
    {
        image: "/images/listings/listing-donmiguel-0.jpg",
        label: "Don Miguel",
        category: "Residential"
    },
    {
        image: "/images/listings/listing-erodriguez-0.jpg",
        label: "E. Rodriguez Commercial Building",
        category: "Commercial"
    },
    {
        image: "/images/listings/listing-ilangilang-0.jpg",
        label: "Ilang-ilang Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-kaingin-0.jpg",
        label: "Kaingin Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-malate-0.jpg",
        label: "Malate Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-may-0.jpg",
        label: "May St.",
        category: "Residential"
    },
    {
        image: "/images/listings/listing-mindanaoavenue-0.jpg",
        label: "Mindanao Avenue",
        category: "Warehouse/Industrial"
    },
    {
        image: "/images/listings/listing-ptuazon-0.jpg",
        label: "P. Tuazon Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-reraon-0.jpg",
        label: "Reraon Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-sanmarcelino-0.jpg",
        label: "San Marcelino Building",
        category: "Commercial"
    },
    {
        image: "/images/listings/listing-tomasmorato-0.jpg",
        label: "Tomas Morato",
        category: "Commercial"
    },
    {
        image: "/images/listings/listing-valencia-0.jpg",
        label: "Valencia Project",
        category: "Townhouse"
    },
    {
        image: "/images/listings/listing-wilson-0.jpg",
        label: "Wilson Project",
        category: "Townhouse"
    }
]