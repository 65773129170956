import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Collage.css'

function Collage({
    collageImage,
    collageColumns,
    collageRows
}) {

    var colSize = 12 / collageColumns;

    return (
        <div className="section-container">
            <Container className="collage-container">
                <Row>
                    {
                        collageImage.map((image) => {
                            return (
                                <Col sm={colSize} className="collage-image">
                                    <img src={image} />
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default Collage
