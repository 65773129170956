export const ourServices = {
    label: "",
    heading: "OUR SERVICES",
    desc: "Ymaz Builders recognizes that the best solutions are derived from providing masters in their respected fields. This approach ensures that only efficient and cost efficient solutions area applied to each project. The company aims to exceeded client's expectations and build structures that last.\n\nRelative to the requirements for the ISO 9001-2015 Quality Management Guidelines conforms under that of the quality management system and standards ensuring the utmost integrity and standard construction processes are established, complemented and maintained."
}

export const serviceDesignPlanning = {
    img: "/images/services/service-designplanning-1.jpg",
    imgAlt: "Design and Planning Management",
    heading: "DESIGN AND PLANNING MANAGEMENT",
    desc: "We are ready to work with your ideas or develop a tailor-fit plan for you in accordance to your budget requirement. By performing due diligence in the pre-construction phase, we are able to minimize your risks and ensure that your project will start off on its best footing. During the construction phase, we take care of coordinating all construction activities, freeing you from stress and worry."
}

export const serviceProjectManagement = {
    img: "/images/services/service-qualitycontrol.jpg",
    imgAlt: "Project Management and Quality Control",
    heading: "PROJECT MANAGEMENT AND QUALITY CONTROL",
    desc: "Bid Evaluation and Award\nConstruction Monitoring and Supervision\nConstruction Management\nContractor's Quality Control Management\nCost Estimating/Budget Consultation\nPlanning and Scheduling\nTotal Project Management\n\nWe aim to ensure our customer's full assistance not only with the development aspect, but also in administrative concerns such as organizing requirements needed for the project's funding and providing a cost-control mechanism for the construction process. We are responsible for the day-to-day oversight of a construction site, management of trades and supplies, and communication of information to involved parties throughout the course of a building project. We are there for you from concept creation to completion.",
}

export const serviceSafetyManagement = {
    img: "/images/services/service-safety.jpg",
    imgAlt: "Project Safety Management",
    heading: "PROJECT SAFETY MANAGEMENT",
    desc: "We value the safety of our team thus, safety procedures are always followed on every job site for the protection of our workers and construction personnel. It is our policy to ensure a sage and healthy workplace for employees, a safe and healthy site for clients and a safe and healthy environment for the community."
}

export const serviceTurnover = {
    img: "images/services/service-turnover.jpg",
    imgAlt: "Property Management and Turnover",
    heading: "PROPERTY MANAGEMENT AND TURNOVER",
    desc: "Our management team values the continuous needs of our clients to finish the units to their satisfaction. Preventing costly and avoidable damage to your house such as ceiling leaks, cracks wall finishes and termite damages requires frequent and periodic maintenance. A scheduled house maintenance is highly recommended, thus we also provide this kind of service for our clients."
}