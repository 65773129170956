import React, {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ProjectCard from './ProjectCard'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Gallery.css'

import { projectListings, filterListings } from '../pages/Project/Data'
import { Link } from 'react-router-dom'

function Gallery() {

    const [items, setItems] = useState([]);
    const [itemFiltered, setItemFiltered] = useState(items);
    const [filterBy, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState("All");

    const filterItems = (itemLocation) => {
        setCurrentFilter(itemLocation);
        const updatedItems =  items.filter((currentItems) => {
            return (itemLocation === "All") ? items : currentItems.category === itemLocation;
        });

        setItemFiltered(updatedItems);
    }

    useEffect( () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        fetch('https://cms.ymaz.bitgoo.cloud/admindashboardcms/api/getprojects', requestOptions)
            .then(results => results.json())
            .then(data => {
                console.log(data.data.projects);

                var items = data.data.projects.filter((projects) => { return (projects.projecttype === "ymaz") && projects.status === "active"});
                setItems(items);
                setItemFiltered(items);
                
                var filters = ["All", ...new Set(items.map(item => {return item.category}))];
                setFilters(filters);
            })
            .catch( error => { console.log(error)})
    }, [] );

    return (
        <div className="section-container">
            <Container>
                <Row >
                    <div className="gallery-filters">
                        {
                            filterBy.map((filter) => {
                                return (
                                    <button className={currentFilter === filter ? "gallery-filter-button active" : "gallery-filter-button"} onClick={() => filterItems(filter)}>{filter}</button>
                                )
                            })
                        }
                    </div>
                </Row>
                <div className="gallery-projects">
                    <Row>
                        {
                            itemFiltered?.map((project) => {
                                return (
                                    <Col sm={6} className="gallery-items">
                                        <Link to={"/projects/" + project.label}>
                                            <ProjectCard projectImage={project.photoUrl[0]} projectLabel={project.label} projectCategory={project.category}/>
                                        </Link>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
                <Row className="gallery-load-more hidden">
                    <button>Load More</button>
                </Row>
            </Container>
        </div>
    )
}

export default Gallery
