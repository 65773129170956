import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { BiEnvelope, BiMap, BiPhone } from 'react-icons/bi'
import { FaFacebookF, FaTwitter, FaInstagram , FaYoutube, FaTiktok } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import './ContactInfo.css'
import { contactInfo } from "./Data"

function ContactInfo({
    lightBackground,
    isColumn,
    hideButton,
    hideLocation,
    hidePhone,
    hideEmail
}) {
    return (
        <div className={ lightBackground ? 'contact-info-container light-background' : 'contact-info-container' }>
            <Row>
                <div className="contact-info-socials hidden"> 
                    <Link to={{ pathname: contactInfo.facebook }} target="_blank">
                        <FaFacebookF/>
                    </Link>
                    <Link to={{ pathname: contactInfo.instagram }} target="_blank">
                        <FaInstagram/>
                    </Link>
                    <Link to={{ pathname: contactInfo.youtoube }} target="_blank">
                        <FaYoutube/>
                    </Link>
                    <Link to={{ pathname: contactInfo.twitter }} target="_blank">
                        <FaTwitter/>
                    </Link>
                    <Link to={{ pathname: contactInfo.tiktok }} target="_blank">
                        <FaTiktok/>
                    </Link>
                </div>
            </Row>
            <Row>
                <Col md={isColumn ? 4 : 12} className={ hideLocation ? "hidden" : isColumn ? "contact-info-details column" : "contact-info-details"}>
                    <p><BiMap/>Location</p>
                    <p>{contactInfo.address}</p>
                </Col>
                <Col md={isColumn ? 4 : 12} className={ hidePhone ? "hidden" : isColumn ? "contact-info-details column" : "contact-info-details"}>
                    <p><BiPhone/>Contact Number</p>
                    <p>{contactInfo.phoneNumber}</p>
                </Col>
                <Col md={isColumn ? 4 : 12} className={ hideEmail ? "hidden" : isColumn ? "contact-info-details column no-margin" : "contact-info-details no-margin"}>
                    <p><BiEnvelope/>Email Address</p>
                    <p>{contactInfo.email}</p>
                </Col>
            </Row>
            <Row>
                <div className={ hideButton ? "hidden" : "contact-info-cta"}>
                    <Link to="/contact">
                        <button className="btn-color-highlight">
                            Contact Us
                        </button>
                    </Link>
                </div>
            </Row>
        </div>
    )
}

export default ContactInfo
