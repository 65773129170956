import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css'

import NavigationBar from './components/NavigationBar';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Projects from './pages/Project/Projects';
import SingleProject from './pages/Project/SingleProject';
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Router>
        <NavigationBar />
        <ScrollToTop />
        <div className='content'>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/services" exact component={Services} />
            <Route path="/projects" exact component={Projects} />
            <Route path="/projects/:project" exact component={SingleProject} />
            <Route path="/contact" exact component={Contact} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
