import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import ContactUs from './ContactUs'

function Contact() {
    return (
        <div>
            <ContactUs />
        </div>
    )
}

export default Contact
