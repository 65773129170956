import React from 'react'
import Hero from '../../components/Hero'

import 'bootstrap/dist/css/bootstrap.min.css'
import { projectHero } from './Data'
import Featured from '../../components/Featured'
import Gallery from '../../components/Gallery'

import { featuredProjects } from "./Data"

function Projects() {
    return (
        <div>
            <Hero {...projectHero} />
            <Gallery />
        </div>
    )
}

export default Projects
