import React, {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Carousel from 'react-bootstrap/Carousel'
import Hero from '../../components/Hero'
import ProjectCard from '../../components/ProjectCard'

import 'bootstrap/dist/css/bootstrap.min.css'
import './SingleProject.css'
import { FaGreaterThan, FaExternalLinkAlt } from 'react-icons/fa'
import { singleProjectHero } from './Data'
import { Link, useParams } from 'react-router-dom'

import listingsData from "./Projects.json";

function SingleProject() {
    const { project } = useParams()
    const [ listing, setListing ] = useState(listingsData.projects[0])

    function refreshPage() {
        setTimeout(()=>{
            window.location.reload(false);
        }, 700);
        console.log('page to reload')
    }

    useEffect( () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"projecttype": "ymaz", "projectname": project})
        };

        fetch('https://cms.ymaz.bitgoo.cloud/admindashboardcms/api/getprojects', requestOptions)
            .then(results => results.json())
            .then(data => {
                //console.log(data.data.projects);

                var items = data.data.projects.filter((projects) => { return (projects.status === "active")});
                var item = items[0];
                setListing(item);
            })
            .catch( error => { console.log(error)})
    }, [] );

    const hero = {...singleProjectHero, "headingTextBold": listing.projectname};

    return (
        <div>
            <Hero {...hero} />
            <Container className="section-container">
                <Row>
                    <div className="project-breadcrumbs">
                        <Link to="/projects">Projects</Link>
                        <FaGreaterThan/>
                        <Link to="/projects">{listing.category}</Link>
                        <FaGreaterThan/>
                        <span>{listing.projectname}</span>
                    </div>
                </Row>
                <Row>
                    <Col md={6} lg={8}>
                        <div className="project-photo-carousel">
                           <Carousel className="project-photo-carousel">
                                {
                                    listing.photoUrl?.map((photoUrl) => {
                                        return (
                                            <Carousel.Item>
                                                <img src={photoUrl} alt={listing.projectname}/>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel>
                        </div>
                    </Col>
                    <Col className="project-details">
                        <div className="section-label">{listing.location}</div>
                        <div className="project-details-name">{listing.projectname}</div>
                        <div className="project-details-others">
                            <div className="project-details-others-heading">Lot Area: </div>
                            <div className="project-details-others-detail">{listing.lotarea}</div>
                        </div>
                        <div className="project-details-others">
                            <div className="project-details-others-heading">Floor Area: </div>
                            <div className="project-details-others-detail">{listing.floorarea}</div>
                        </div>
                        <div className="project-details-others">
                            <div className="project-details-others-heading">Turnover Date: </div>
                            <div className="project-details-others-detail">{listing.turnoverdate}</div>
                        </div>
                        <div className="project-details-others">
                            <div className="project-details-others-heading">Location: </div>
                            <div className="project-details-others-detail">{listing.address}</div>
                        </div>
                        <div className={listing.propertyDetails !== "" ? "project-details-others" : "hidden"}>
                            <div className="project-details-others-heading">Property Details: </div>
                            <div className="project-details-others-detail">{listing.propertydetails}</div>
                        </div>
                        <div className={listing.tour !== "" ? "project-details-others" : "hidden"}>
                            <div className="project-details-others-heading">Tour: </div>
                            <div className="project-details-others-detail">
                                <Link to={{ pathname: listing.tour }} target="_blank">
                                    {listing.projectname} House Tour
                                    <FaExternalLinkAlt/>
                                </Link>
                            </div>
                        </div>
                        <div className={listing.projectnote !== "" ? "project-notes" : "hidden"}>
                            <div className='project-notes-details'>{listing.projectnote}</div>
                        </div>
                        <Link to="/contact">
                            <button className="btn-color-highlight">Contact Us</button>
                        </Link>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <div className="project-related-properties-label">Related Properties</div>
                </Row>
                <Row className="project-related-properties-items">
                    {
                        listing.relatedProjects?.map((project) => {
                            return (
                                <Col sm={12} md={6} lg={4} className="related-property">
                                    <Link onClick={refreshPage} to={"/projects/" + project.projectName}>
                                        <ProjectCard projectImage={project.photoUrl} projectLabel={project.projectName} projectCategory={project.category} />
                                    </Link>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}

export default SingleProject
