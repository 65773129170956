import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Collage from '../../components/Collage'

import 'bootstrap/dist/css/bootstrap.min.css'
import './About.css'
import { companyAbout, collageAbout } from './Data'

import imgAbout from "../../assets/images/image-about.jpg"

function Company() {
    return (
        <div>
            <div className="section-container">
                <Container>
                    <Row className="initial-full-height-footer">
                        <Col lg={6} className="section-image">
                            <img src={imgAbout} alt="Ymaz Builders"/>
                        </Col>
                        <Col lg={6} className="section section-padding">
                            <Row>
                                <div className="section-heading">
                                    {companyAbout.heading}
                                </div>
                            </Row>
                            <Row>
                                <div className="aboutus-desc">
                                    <p>{companyAbout.desc}</p>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Company
