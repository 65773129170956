export const companyAbout = {
    label: "",
    heading: "WHO WE ARE",
    desc: "With more than 20 years of experience in the field of Construction Management, Ymaz Builders has dedicated itself to providing specialized project management techniques to oversee the planning, design, and over all construction of our projects.\n\nRendering professional service from start up to finish, our highly-trained and experienced Licensed Civil Engineers and Architects are the backbone of our service. Professional construction management has always been the thrust of Ymaz Builders thus, continuous quality performance of our management team ensures that there is quality control in every aspect of the project's construction timeline. From estimate to delivery of materials, to project costing and conceptual budgeting to ensuring that only quality execution of construction works are rendered to its project completion.\n\nEvery Ymaz Builders unit managed is geared towards upgrading the standard of living of every Filipino family. Not just any luxurious house, but a home that will introduce you to a lifestyle change and a new kind way of living that you truly deserve to own and live in."
}

export const collageAbout = [
    "images/collage/collage-b-2.jpg",
    "images/collage/collage-c-1.jpg",
    "images/collage/collage-d-4.jpg"
]