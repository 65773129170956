import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './NavigationBar.css'

import { contactInfo } from '../pages/Contact/Data'
import logo from '../assets/images/logo-ymaz.png'
import logoWhite from '../assets/images/logo-ymaz-white.png'
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaTiktok, FaBars, FaTimes } from 'react-icons/fa'

function NavigationBar({act}) {

    const currentLocation = useLocation().pathname;

    const [showMenu, setShow] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(window.innerWidth);
    const toggleMobileMenu = () => {
        setShow(!showMenu)
    }

    const closeMobileMenu = () => {
        setShow(false)
    }

    const windowResize = () => {
        if (window.innerWidth <= 990) {
            setMobileMenu(true);
            setShow(false)
        } else {
            setMobileMenu(false);
            setShow(false)
        }
    }

    useEffect(() => {
        windowResize();
    }, [])

    window.addEventListener('resize', windowResize);

    return (
        <>
            <nav className={showMenu ? "navbar-yb active" : "navbar-yb"}>
                <div className="navbar-container">
                    <div className={mobileMenu ? "navbar-logo mobile" : "navbar-logo desktop"}>
                        <Link to="/" className="logo">
                            <img src={logo} alt="YMAZ Builders"/>
                        </Link>
                    </div>

                    <div className={mobileMenu ? "navbar-links mobile" : "navbar-links desktop"}>
                        <Link to="/" className={currentLocation === "/" ? "active" : ""}>Home</Link>
                        <Link to="/about" className={currentLocation === "/about" ? "active" : ""}>About</Link>
                        <Link to="/services" className={currentLocation === "/services" ? "active" : ""}>Services</Link>
                        <Link to="/projects" className={currentLocation.includes("/projects") ? "active" : ""}>Projects</Link>
                        <Link to="/contact" className={currentLocation === "/contact" ? "active" : ""}>Contact</Link>
                    </div>
                    
                    <div className={mobileMenu ? "navbar-social mobile" : "navbar-social desktop"}>
                            <Link to={{ pathname: contactInfo.facebook }} target="_blank">
                                <FaFacebookF/>
                            </Link>
                            <Link to={{ pathname: contactInfo.instagram }} target="_blank">
                                <FaInstagram/>
                            </Link>
                            <Link to={{ pathname: contactInfo.youtoube }} target="_blank">
                                <FaYoutube/>
                            </Link>
                            <Link to={{ pathname: contactInfo.twitter }} target="_blank">
                                <FaTwitter/>
                            </Link>
                            <Link to={{ pathname: contactInfo.tiktok }} target="_blank">
                                <FaTiktok/>
                            </Link>
                        </div>

                    <div className={mobileMenu ? "navbar-menu-icon mobile" : "navbar-menu-icon desktop"}> 
                        {
                            showMenu ? <FaTimes size={"1.6em"} onClick={toggleMobileMenu} /> : <FaBars size={"1.6em"} onClick={toggleMobileMenu}/>
                        }
                    </div>

                </div>
                <div className={showMenu ? "nav-menu active" : "nav-menu"}>
                    <div className="navbar-logo">
                        <Link to="/" className="logo">
                            <img src={logoWhite} alt="YMAZ Builders"/>
                        </Link>
                    </div>
                    <Link to="/" className={currentLocation === "/" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Home</Link>                
                    <Link to="/about" className={currentLocation === "/about" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >About</Link>
                    <Link to="/services" className={currentLocation === "/services" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Services</Link>
                    <Link to="/projects" className={currentLocation.includes("/projects") ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Projects</Link>
                    <Link to="/contact" className={currentLocation === "/contact" ? "nav-menu-links active" : "nav-menu-links"} onClick={closeMobileMenu} >Contact</Link>
                    <div className="navbar-social">
                        <Link to={{ pathname: contactInfo.facebook }} target="_blank">
                            <FaFacebookF/>
                        </Link>
                        <Link to={{ pathname: contactInfo.instagram }} target="_blank">
                            <FaInstagram/>
                        </Link>
                        <Link to={{ pathname: contactInfo.youtoube }} target="_blank">
                            <FaYoutube/>
                        </Link>
                        <Link to={{ pathname: contactInfo.twitter }} target="_blank">
                            <FaTwitter/>
                        </Link>
                        <Link to={{ pathname: contactInfo.tiktok }} target="_blank">
                            <FaTiktok/>
                        </Link>
                    </div>
                </div>
            </nav>  
        </>
    )
}

export default NavigationBar
