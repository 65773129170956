import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import './ProjectCard.css'

function ProjectCard({
    projectImage,
    projectLabel,
    projectCategory
}) {
    return (
        <div className="project-card">
            <img src={projectImage} alt={projectLabel}/>
            <div className="project-card-label">{projectLabel}</div>
            <div className="project-card-category">{projectCategory}</div>
        </div>
    )
}

export default ProjectCard
