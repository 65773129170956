import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import ContactInfo from './ContactInfo'
import ContactForm from './ContactForm'

import imgContact from '../../assets/images/image-contact-us.jpg'

import 'bootstrap/dist/css/bootstrap.min.css'
import './ContactUs.css'

function ContactUs() {
    return (
        <div className="section-container">
            <Container>
                <Row className="initial-full-height-footer">
                    <Col lg={6} className="section-image">
                        <img src={imgContact} alt="Contact Us"/>
                    </Col>
                    <Col lg={6} className="section section-padding">
                        <Row>
                            <div className="section-heading">
                                Contact Us
                            </div>
                            <ContactForm />
                            <ContactInfo lightBackground={true} hideButton={true} hideLocation={false} hidePhone={false} hideEmail={false}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactUs
