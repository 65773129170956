import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import 'bootstrap/dist/css/bootstrap.min.css'
import './Services.css'
import { ourServices, serviceDesignPlanning, serviceProjectManagement, serviceSafetyManagement, serviceTurnover } from './Data'
import imgOurServices from '../../assets/images/image-our-services.jpg'

function Services() {
    return (
        <div>
            <div className="section-container">
                <Container>
                    <Row className="initial-full-height">
                        <Col lg={6} className="section-image">
                            <img src={imgOurServices} alt="Our Services"/>
                        </Col>
                        <Col lg={6} className="section section-padding">
                            <Row>
                                <div className="section-heading">
                                    {ourServices.heading}
                                </div>
                            </Row>
                            <Row>
                                <div className="aboutus-desc">
                                    <p>{ourServices.desc}</p>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="bg-gray service-container section-container">
                <Row className="service-content">
                    <div className="service-heading">
                        {serviceDesignPlanning.heading}
                    </div>
                    <div className="service-desc">
                        {serviceDesignPlanning.desc}
                    </div>
                </Row>
                <Row className="service-img">
                    <img src={serviceDesignPlanning.img} alt={serviceDesignPlanning.imgAlt}/>
                </Row>
            </Container>
            <Container className="service-container section-container">
                <Row className="service-content">
                    <div className="service-heading">
                        {serviceProjectManagement.heading}
                    </div>
                    <div className="service-desc">
                        {serviceProjectManagement.desc}
                    </div>
                </Row>
                <Row className="service-img">
                    <img src={serviceProjectManagement.img} alt={serviceProjectManagement.imgAlt}/>
                </Row>
            </Container>
            <Container className="bg-gray service-container section-container">
                <Row className="service-content">
                    <div className="service-heading">
                        {serviceSafetyManagement.heading}
                    </div>
                    <div className="service-desc">
                        {serviceSafetyManagement.desc}
                    </div>
                </Row>
                <Row className="service-img">
                    <img src={serviceSafetyManagement.img} alt={serviceSafetyManagement.imgAlt}/>
                </Row>
            </Container>
            <Container className="service-container section-container">
                <Row className="service-content">
                    <div className="service-heading">
                        {serviceTurnover.heading}
                    </div>
                    <div className="service-desc">
                        {serviceTurnover.desc}
                    </div>
                </Row>
                <Row className="service-img">
                    <img src={serviceTurnover.img} alt={serviceTurnover.imgAlt}/>
                </Row>
            </Container>
        </div>
    )
}

export default Services
